<template>
  <div class="content md no-padding flex-box vertical">
    <div class="area vertical">
      <div class="area-content">
        <a-form-model
          class="flex-grow"
          ref="editForm"
          :model="form"
          :rules="rules"
          layout="vertical"
          label-align="left"
        >
          <a-form-model-item label="启用供奉物品" prop="open">
            <a-switch v-model="form.open"></a-switch>
          </a-form-model-item>
          <a-form-model-item label="启用一体机退款" prop="open">
            <a-switch v-model="form.refund"></a-switch>
          </a-form-model-item>
          <a-form-model-item label="供奉人员">
            <select-book type="user" multiple v-model="form.user_ids"></select-book>
          </a-form-model-item>
        </a-form-model>
      </div>
    </div>
    <a-space class="footer">
      <a-button @click="back">取消</a-button>
      <a-button type="primary" @click="handleSave">保存</a-button>
    </a-space>
  </div>
</template>

<script>
export default {
  name: "GoodsSet",
  data() {
    return {
      form: {
        open: false,
      },
      rules: {
        open: [{ required: true, message: "请选择是否启用", trigger: "change", type: "boolean" }],
      },
    };
  },
  created() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      this.$axios({
        url: "/admin/mini-worship-item/get-config",
        method: "GET",
        params: {},
      }).then((res) => {
        if (res.error == 0) {
          let data = res.data;
          if (data) {
            this.form = typeof data === "string" ? JSON.parse(data) : data;
          }
        }
      });
    },
    handleSave() {
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          this.$axios({
            url: "/admin/mini-worship-item/set-config",
            method: "POST",
            data: this.form,
          }).then(() => {
            this.$message.success("保存成功");
            this.back();
          });
        }
      });
    },
    back() {
      this.$router.back();
    },
  },
};
</script>

<style lang="less" scoped>
.footer {
  width: 100%;
}
.mb-24 {
  margin-bottom: 24px;
}
</style>
